export const acceptedPreviewType = (fileExtension: string) => {
    switch (fileExtension) {
        case "pdf":
        case ".pdf":
        case "doc":
        case "gdoc":
        case "docx":
        case "word":
            return "pdf";
        case "":
        case "usdz":
        case "btca":
        case "btc":
        case "btcp":
        case "btcn":
        case "btce":
        case "btcd":
        case "btcf":
        case "csv":
        case "xls":
        case "xlsx":
        case "excel":
        case "potx":
        case "pps":
        case "ppsx":
        case "ppt":
        case "pptx":
        case "powerpoint":
            return "btc";
        case "aac":
        case "aif":
        case "au":
        case "flac":
        case "m4a":
        case "mp3":
        case "ogg":
        case "wav":
        case "3gpp":
        case "3gp":
        case "mp4":
        case "mpg":
        case "avi":
        case "wmv":
        case "mov":
        case "flv":
        case "m4v":
        case "mkv":
        case "video":
            return "video";
        case "bmp":
        case "gif":
        case "jpg":
        case "jpeg":
        case "png":
        case "svg":
        case "tiff":
        case "tif":
        case "image":
            return "image";
        case "dwg":
        case "dxf":
        case "kml":
        case "kmz":
        case "ebook":
        case "mobi":
        case "epub":
        case "folder":
        case "ibooks":
        case "ipa":
        case "key":
        case "keynote":
        case "numbers":
        case "oomph":
        case "pages":
        case "mpp":
        case "mobileprovision":
        case "mobileconfig":
        case "prov":
        case "rtf":
        case "rtfd":
        case "scrollmotiontransit":
        case "twixl":
        case "txt":
        case "text":
        case "vcard":
        case "vcf":
        case "vsd":
        case "vsdx":
        case "vsx":
        case "vss":
        case "vtx":
        case "vdx":
        case "vdw":
        case "vst":
        case "htm":
        case "html":
        case "web":
        case "tar.gz":
        case "tar":
        case "rar":
        case "zip":
        case "7z":
            return "embeddedIframe";
        default:
            return "";
    }
}