import * as React from "react";
import * as classNames from "classnames/bind";
import Blankslate from "components/Blankslate/Blankslate";
import Loader from "components/Loader/Loader";
import styles from "./Index.less";
import ucsService from "../../service/UcsService";
import { UnifiedContentPlayer } from '@bigtincan/unified-content-player';

const NonHubFileViewer = ({ match }) => {
    const [state, setState] = React.useState({
        activeFile: {},
        isLoading: true,
        hasError: false,
        error: "",
        urn: "",
        previewUrl: "",
        previewType: "",
        nonHubFile: false
    });

    const authString = React.useRef("");

    React.useEffect(() => {
        const urn = match.params.urn;
        const token = match.params.token;;
        const region = match.params.region;

        setState(prevState => ({
            ...prevState,
            urn
        }));

        let authToken = token || localStorage.getItem("BTCTK_A");
        if (token) {
            localStorage.setItem("BTCTK_A", token);
        }
        authString.current = "&access_token=" + authToken;

        if (region) {
            const global = window as any;
            const regions = global.PLUGINCONFIG.REGIONS;
            const selectedRegion = regions.find((item) => item.code === region);
            global.BTC = selectedRegion.apiServer;
            global.BTC_APP_URL = selectedRegion.value;
            localStorage.setItem("serverprofile", JSON.stringify(selectedRegion));
        }

        if (urn) {
            ucsService.getFilePreviewLink(previewType, decodeURIComponent(urn)).then((data: any) => {
                setState(prevState => ({
                    ...prevState,
                    previewUrl: data.url,
                    previewType: data.type,
                    nonHubFile: true,
                    isLoading: false,
                    hasError: false,
                    error: "",
                }));
            }).catch((err: any) => {
                setState(prevState => ({
                    ...prevState,
                    isLoading: false,
                    hasError: true,
                    error: "An error occurred while fetching the file preview link.",
                }));
            });
        }

    }, []);

    const onLoadInfoCallback = () => { };

    const { hasError, error, isLoading, nonHubFile, previewType, previewUrl } = state;
    const cx = classNames.bind(styles);
    const classes = cx({}, styles.container);
    return (
        <div className={classes}>
            <div className={styles.viewercontainer}>
                {!isLoading && !hasError && nonHubFile &&
                    <div style={{ width: "100%", height: "100%", alignSelf: "center" }}>
                        <UnifiedContentPlayer
                            contentType={previewType}
                            sourcePath={previewUrl}
                            websocketUrl='wss://interactive-slides-api-int.clearslideng.com'
                            role='presenter'
                            thumbnail=''
                            isAudio={false}
                            autoplay={false}
                            onLoadInfoCallback={onLoadInfoCallback}
                        />
                    </div>
                }
                {isLoading && !hasError &&
                    <div className={styles.center}>
                        <Loader type="content" />
                    </div>
                }
                {hasError &&
                    <div className={styles.center}>
                        <Blankslate
                            className={styles.error}
                            icon="error"
                            heading={error}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default NonHubFileViewer;
