import apiClient from "./ApiClient";
import { acceptedPreviewType } from "../helpers/acceptedPreviewTypes";

class UcsService {
    async getFilePreviewLink(fileExtension: string, btcUrn: string) {
        const previewType = acceptedPreviewType(fileExtension);

        return new Promise(async (resolve, reject) => {
            try {
                const response: any = await apiClient.get(`/v1/content/${btcUrn}/preview`, "ucsapi", {
                    params: previewType ? { "acceptedPreviewTypes": previewType } : {},
                });

                if (response && response.data) {
                    resolve(response.data);
                }
            } catch (error) {
                reject(error);
            }
        });
    }
}

const ucsService = new UcsService();
export default ucsService;


