/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-starter
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

import "core-js/features/array/at"; // required for safari
import "babel-polyfill";  // required for IE
import * as React from "react";
import * as ReactDOM from "react-dom";
import { AppContainer } from "react-hot-loader";
import { IntlProvider } from "react-intl";
import loadLocaleData from "./helpers/loadLocaleData";
import App from "./containers/App/index";
import Home from "./containers/Home/Index";
import { SignIn } from "./containers/SignIn/Index";
import { Layout } from "./containers/Layout/Index";
import { HashRouter, Route, Switch } from "react-router-dom";
import { FileViewer } from "./containers/FileViewer/Index";
import { FileRecommended } from "./containers/FileRecommended/Index";
import "./react-widgets/less/global.less";
import "./less/global.less";
import NonHubFileViewer from "./containers/NonHubFileViewer";

const locale = "en";

window.cachesOppData = [];
window.validateBody = (event) => {
  const mailboxItem = window.Office.context.mailbox.item;
  mailboxItem.body.getAsync("html", { asyncContext: event }, (asyncResult) => {
    asyncResult.asyncContext.completed({ allowEvent: false });
  });
};
const fixedApiProfile = () => {
  const profile = localStorage.getItem("serverprofile");
  if (profile) {
    const global: any = window;
    const selected = JSON.parse(profile);
    global.BTC = selected.apiServer;
    global.BTC_APP_URL = selected.value;
  }
};
// Load locale strings and render to DOM
const app = async () => {

  function renderApp(props) {
    return <App  {...props} />;
  }

  function renderSignIn(props) {
    return <SignIn {...props} />;
  }

  function renderHome(props) {
    return <Home {...props} />;
  }

  function renderFileRecommended(props) {
    return <FileRecommended {...props} />;
  }

  function renderFileViewer(props) {
    return <FileViewer {...props} />;
  }

  function renderNonHubFileViewer(props) {
    return <NonHubFileViewer {...props} />;
  }


  loadLocaleData(locale).then((messages) => {
    fixedApiProfile();
    ReactDOM.render(
      <IntlProvider defaultLocale="en" locale={locale} messages={messages}>
        <AppContainer>
          <Layout>
            <HashRouter>
              <Switch>
                <Route exact={true} path="/" render={renderApp} />
                <Route exact={true} path="/signin" render={renderSignIn} />
                <Route exact={true} path="/home" render={renderHome} />
                <Route exact={true} path="/filerecommended" render={renderFileRecommended} />
                <Route exact={true} path="/viewer/:fileId/:token" render={renderFileViewer} />
                <Route exact={true} path="/viewer/:fileId/:token/:region" render={renderFileViewer} /> {/* Used to file preview in Gmail add on */}
                <Route exact={true} path="/file-viewer/:urn/:token/:region" render={renderNonHubFileViewer} />
                {/* <Route exact={true} path="/browse" render={renderBrowse} /> */}
              </Switch>
            </HashRouter>
          </Layout>
        </AppContainer>
      </IntlProvider>, document.getElementById("root"),
    );
  });
};
if (window.Office) {
  // tslint:disable-next-line:no-empty
  window.history.pushState = () => { };
  // tslint:disable-next-line:no-empty
  window.history.replaceState = () => { };
  (window as any).Office.initialize = () => {
    delete (window as any).alert;
  };
}

app();
